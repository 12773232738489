import { useContext, useEffect } from "react"
import { navigate } from "gatsby"

import { LangContext } from "../context/LangContext"

export default () => {
  const { pageLang } = useContext(LangContext)

  useEffect(() => {
    navigate(`${pageLang === "en" ? `/` : `${pageLang}/`}`)
  }, [pageLang])

  return null
}
